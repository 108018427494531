<template>
    <div class="user-layout">
        <div class="user-layout-header">
            <UserHeader :userInfo="userInfo" :isSelf="isSelf" @onUpdateBiography="onUpdateBiography"
                @onUpdateFollow="val => userInfo.hasFollow = val" @onUpdateNickName="val => userInfo.name = val"></UserHeader>
        </div>
        <div class="user-layout-content">
            <div class="left-content">
                <a-tabs v-model="activeTabkey" @change="handleTabChange">
                    <!-- <a-tab-pane key="post-list" tab="帖子">
                                    <div class="post-list-header">
                                        <div class="header-item">
                                            <router-link :to="{ name: 'bbsUserPosts' }" exact active-class="active-router-link">
                                                ta的帖子
                                            </router-link>
                                            <span class="header-item-count">
                                                12
                                            </span>
                                        </div>
                                        <div class="header-item">
                                            <router-link  :to="{ name: 'bbsUserReplyList' }" exact active-class="active-router-link">
                                                ta的回帖
                                            </router-link>
                                            <span class="header-item-count">
                                                12
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <router-view></router-view>
                                    </div>
                                </a-tab-pane>
                                <a-tab-pane key="subscribe" tab="关注">
                                    <div class="subscribe-list-header">
                                        <div class="header-item">
                                            <router-link :to="{ name: 'bbsUserFollow' }" exact active-class="active-router-link">
                                                ta的关注
                                            </router-link>
                                            <span class="header-item-count">
                                                12
                                            </span>
                                        </div>
                                        <div class="header-item">
                                            <router-link  to="/bbs/user/followers" exact active-class="active-router-link">
                                                关注他的
                                            </router-link>
                                            <span class="header-item-count">
                                                12
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <router-view></router-view>
                                    </div>
                                </a-tab-pane> -->
                    <a-tab-pane v-for="(tabItem, index) in tabList" :key="tabItem.key" :tab="tabItem.tab">
                        <div v-if="tabItem.key === 'post-list'">
                            <div class="post-list-header">
                                <div class="header-item">
                                    <router-link :to="{
                                        name: 'bbsUserPosts', query: {
                                            ...$route.query
                                        }
                                    }" exact active-class="active-router-link">
                                        {{ isSelf ? '我' : 'ta' }}的帖子
                                    </router-link>
                                    <span class="header-item-count">
                                        {{ postCount }}
                                    </span>
                                </div>
                                <div class="header-item">
                                    <router-link :to="{
                                        name: 'bbsUserReplyList', query: {
                                            ...$route.query
                                        }
                                    }" exact active-class="active-router-link">
                                        {{ isSelf ? '我' : 'ta' }}的回帖
                                    </router-link>
                                    <span class="header-item-count">
                                        {{ commentCount }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <router-view v-if="activeTabkey === 'post-list' && userInfo"
                                    @onChangePostCount="onChangePostCount" :userInfo="userInfo"></router-view>
                            </div>
                        </div>
                        <div v-else-if="tabItem.key === 'subscribe'">
                            <div class="subscribe-list-header">
                                <div class="header-item">
                                    <router-link :to="{
                                        name: 'bbsUserFollow', query: {
                                            ...$route.query
                                        }
                                    }" exact active-class="active-router-link">
                                        {{ isSelf ? '我' : 'ta' }}的关注
                                    </router-link>
                                    <span class="header-item-count">
                                        {{ followCount }}
                                    </span>
                                </div>
                                <div class="header-item">
                                    <router-link :to="{
                                        name: 'bbsUserFollowerList', query: {
                                            ...$route.query
                                        }
                                    }" exact active-class="active-router-link">
                                        关注{{ isSelf ? '我' : 'ta' }}的
                                    </router-link>
                                    <span class="header-item-count">
                                        {{ followMeCount }}
                                    </span>
                                </div>
                            </div>
                            <div v-if="activeTabkey === 'subscribe' && userInfo">
                                <router-view @onChangeFollowCount="onChangeFollowCount" :userInfo="userInfo"></router-view>
                            </div>
                        </div>
                        <div v-else-if="tabItem.key === 'collection' && userInfo">
                            <div class="collection-list-header">
                                <div class="header-item">
                                    <router-link :to="{
                                        name: 'bbsUserPostCollection', query: {
                                            ...$route.query
                                        }
                                    }" exact active-class="active-router-link">
                                        我的收藏
                                    </router-link>
                                    <span class="header-item-count">
                                        {{ collectCount }}
                                    </span>
                                </div>
                            </div>
                            <div v-if="activeTabkey === 'collection'  && userInfo">
                                <router-view @onCollectCountChange="onCollectCountChange" :userInfo="userInfo"></router-view>
                            </div>
                        </div>
                        <div v-else-if="tabItem.key === 'drafts'">
                            <div class="draft-list-header">
                                <div class="header-item">
                                    <router-link :to="{ name: 'bbsUserDrafts', query: {
                    ...$route.query
                } }" exact active-class="active-router-link">
                                        我的草稿箱
                                    </router-link>
                                    <span class="header-item-count">
                                        {{ draftCount }}
                                    </span>
                                </div>
                            </div>
                            <div v-if="activeTabkey === 'drafts'">
                                <router-view @onChangeDraftCount="onChangeDraftCount"></router-view>
                            </div>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </div>
            <div class="right-content">
                <UserBaseInfoCard :userCountInfo.sync="infoCount"></UserBaseInfoCard>
                <ResearchTeamCard v-if="isSelf" style="margin-top: 20px;">

                </ResearchTeamCard>
                <div v-if="isSelf" class="recommand-research-team-ctn">
                    <SideGapCard title="我关注的话题" gapColor="#2F54EB">
                        <template>
                            <a-skeleton :loading="isRecomandTopicLoading" active :title="false"
                                :paragraph="{ rows: 10, width: '100%' }"
                                :style="{ padding: isRecomandTopicLoading ? '20px' : '' }">
                                <div class="recomand-list">
                                    <div v-for="(item, index) in recommandTopicList">
                                        <TopicListItem :index="index" :subjectData="item"
                                            @onUpdate="newVal => item.isFollow = newVal.isFollow"></TopicListItem>
                                    </div>
                                </div>
                                <a-empty v-if="recommandTopicList.length === 0" style="margin: 20px;"></a-empty>
                            </a-skeleton>

                        </template>
                    </SideGapCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { computed, onBeforeMount, onBeforeUpdate, onMounted, ref, watch } from 'vue-demi';
import SideGapCard from '../../components/sideGapCard.vue';
import ResearchTeamCard from '../components/researchTeamCard.vue';
import TopicListItem from '../components/topicListItem.vue';
import UserBaseInfoCard from '../components/userBaseInfoCard.vue';
import UserHeader from '../components/userHeader.vue';
import { getUserCenterUserInfo, getUserCenterCount, getMyFollowSubjectList } from '@/api/paperManage.js';
import store from '../../../../store';
export default {
    components: {
        UserHeader,
        UserBaseInfoCard,
        ResearchTeamCard,
        SideGapCard,
        TopicListItem
    },
    setup(props, { root }) {

        const activeTabkey = ref('');



        const handleTabChange = (val) => {
            const { $route, $router } = root;
            if (activeTabkey.value == 'post-list' && !['bbsUserReplyList', 'bbsUserPosts'].includes($route.name)) {
                $router.replace({
                    name: 'bbsUserPosts', query: {
                        ...$route.query
                    }
                })
            }
            else if (activeTabkey.value == 'subscribe' && !['bbsUserFollowerList', 'bbsUserFollow'].includes($route.name)) {
                $router.replace({
                    name: 'bbsUserFollow', query: {
                        ...$route.query
                    }
                })
            }
            else if (activeTabkey.value == 'collection' && !['bbsUserPostCollection'].includes($route.name)) {
                $router.replace({
                    name: 'bbsUserPostCollection', query: {
                        ...$route.query
                    }
                })
            }
            else if (activeTabkey.value == 'drafts' && !['bbsUserDrafts'].includes($route.name)) {
                $router.replace({
                    name: 'bbsUserDrafts', query: {
                        ...$route.query
                    }
                })
            }
        }

        const userInfo = ref(null);

        const infoCount = ref(null)

        // 获取用户信息
        const getInfo = async () => {
            const res = await getUserCenterUserInfo({
                userid: $route.params.id
            });
            if (res.success) {
                userInfo.value = res.data;
                if($route.params.id == 0) {
                    document.title = '我的主页'
                }
                else {
                    document.title = `${userInfo.value.name} - 个人主页`
                }
            }
        }

        // 获取消息数量
        const getInfoCount = async () => {
            const res = await getUserCenterCount({
                userid: $route.params.id
            });
            if (res.success) {
                infoCount.value = res.data;
            }
        }
        const { $route } = root;
        const isSelf = computed(() => {

            return $route.params.id === '0';
        })
        const tabList = computed(() => {
            if (isSelf.value) {
                const res = [
                    {
                        tab: '帖子',
                        key: 'post-list'
                    },
                    {
                        tab: '收藏',
                        key: 'collection'
                    },
                    {
                        tab: '关注',
                        key: 'subscribe'
                    },
                    {
                        tab: '草稿箱',
                        key: 'drafts'
                    }
                ]
                return res;
            }
            else {
                const res = [
                    {
                        tab: '帖子',
                        key: 'post-list'
                    },
                    {
                        tab: '关注',
                        key: 'subscribe'
                    }
                ]
                return res;
            }
        })

        onBeforeMount(() => {
            const { $route, $router } = root;

            const userId = store.state.userInfo ?  store.state.userInfo.userId : null;
            if(userId && $route.params.id != 0 && $route.params.id  == userId) {
                $router.replace({
                    name: $route.name,
                    params: {
                        id: 0
                    },
                    query: {
                        ref: $route.query.ref ?  $route.query.ref +  Math.ceil(Math.random() * 1e4) : new Date().getTime()
                    }
                })
                location.reload();
                return 
            }
            

            if (['bbsUserFollowerList', 'bbsUserFollow'].includes($route.name)) {
                activeTabkey.value = 'subscribe'
            }
            else if (['bbsUserReplyList', 'bbsUserPosts'].includes($route.name)) {
                activeTabkey.value = 'post-list'
            }
            else if (['bbsUserPostCollection'].includes($route.name)) {
                activeTabkey.value = 'collection'
            }
            else if (['bbsUserDrafts'].includes($route.name)) {
                activeTabkey.value = 'drafts'
            }
            if (isSelf.value) {
                initRecommandList();
            }
        })

        onMounted(() => {
            getInfo();
            getInfoCount();
        })

        // 推荐话题是否在加载
        const isRecomandTopicLoading = ref(true);

        // 推荐话题列表
        const recommandTopicList = ref([]);

        // 初始化推荐列表
        const initRecommandList = async () => {
            isRecomandTopicLoading.value = true;
            /*   setTimeout(() => {
                  //recommandTopicList.value = [1,2,3,4,5,6,8,1,2,3];
                  isRecomandTopicLoading.value = false;
              }, 2000) */
            const res = await getMyFollowSubjectList({
                pagesize: 5,
                pageno: 1
            });
            if (res.success) {
                //recommandTopicList.value = res.data
                recommandTopicList.value = res.data.data ? res.data.data : [];
            }
            isRecomandTopicLoading.value = false;
        }

        // 修改个人简介成功回调
        const onUpdateBiography = (val) => {
            userInfo.value.biography = val;
        }

        const draftCount = ref(''); // 草稿数量

        const onChangeDraftCount = (count) => {
            draftCount.value = count;
        }

        const collectCount = ref('');   // 收藏数量

        // 收藏改变
        const onCollectCountChange = (count) => {
            collectCount.value = count;
        }

        const postCount = ref('');  // 帖子数

        const commentCount = ref('');   // 回帖数


        const followCount = ref('') // 关注数量

        const followMeCount = ref('');  // 关注我的数量

        const onChangeFollowCount = (follow, followMe) => {
            followCount.value = follow;
            followMeCount.value = followMe;
        }

        // 帖子列表回调
        const onChangePostCount = (newPostCount, newCommentCount) => {
            postCount.value = newPostCount;
            commentCount.value = newCommentCount;
        }


        return {
            activeTabkey,
            handleTabChange,
            userInfo,
            tabList,
            isSelf,
            isRecomandTopicLoading,
            recommandTopicList,
            infoCount,
            onChangeDraftCount,
            draftCount,
            collectCount,
            onCollectCountChange,
            onUpdateBiography,
            postCount,
            commentCount,
            onChangePostCount,
            followCount,
            followMeCount,
            onChangeFollowCount
        }
    },
}
</script>

<style lang="less" scoped>
.user-layout-content {
    display: flex;
    margin-top: 20px;
    margin-bottom: 80px;

    .left-content {
        width: 0px;
        flex: 1;
        background: #fff;
        border: .5px solid #EBEBEB;

        /deep/ .ant-tabs-nav-scroll {
            margin-left: 20px;
        }

        /deep/ .ant-tabs-nav .ant-tabs-tab {
            padding: 14px 14px;

            &:hover {
                color: @srims-primary-color;
            }
        }

        /deep/ .ant-tabs-ink-bar {
            background-color: @srims-primary-color;
            // transform: scaleX(0.5) !important;
        }

        /deep/ .ant-tabs-nav .ant-tabs-tab-active {
            color: @srims-primary-color;
        }

        .post-list-header,
        .subscribe-list-header,
        .collection-list-header,
        .draft-list-header {
            display: flex;
            border-bottom: .5px solid #EBEBEB;
            margin: 0px 20px;

            .header-item {
                padding: 0px 20px 13px 20px;
                font-size: 14px;

                a {
                    color: rgba(0, 0, 0, 0.65);
                }

                .active-router-link {
                    color: @srims-primary-color;
                }
            }

            .header-item-count {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 15px;
            }
        }
    }

    .right-content {
        margin-left: 20px;
        width: 330px;

        .recommand-research-team-ctn {
            margin-top: 20px;

            /deep/ .gap-card-content {
                padding: 0px;
            }
        }
    }
}</style>