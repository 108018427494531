<!-- 用户中心的我加入/我管理的课题组侧边卡片 -->
<template>
    <div class="research-team-card">
        <a-collapse v-model="activeKey" expand-icon-position="right">
            <a-collapse-panel key="join" :header="`我参与的课题组（${joinList.length}）`">
                <template slot="extra">
                    <a-icon v-if="isListLoading" type="loading" />
                </template>
                <a-spin :spinning="isListLoading" tip="正在加载">
                    <a-empty v-if="joinList.length === 0"></a-empty>
                    <div v-else>
                        <a :href="getResearchTeamLink(item)" target="_blank" v-for="(item,index) in joinList" class="research-team-item">
                            <div class="research-team-cover-ctn">
                                <ResearchGroupIcon :name="item.name"></ResearchGroupIcon>
                            </div>
                            <div class="research-team-info">
                                <div class="team-name">
                                    {{ item.name }}
                                </div>
                                <div class="member-count">
                                    {{ item.userCount }} 人
                                </div>
                            </div>
                        </a>
                    </div>
                </a-spin>
                
            </a-collapse-panel>
            <a-collapse-panel key="manage" :header="`我管理的课题组（${manageList.length}）`">
                <template slot="extra">
                    <a-icon v-if="isListLoading" type="loading" />
                </template>
                <a-spin :spinning="isListLoading" tip="正在加载">
                    <a-empty v-if="manageList.length === 0"></a-empty>
                    <div v-else>
                        <a :href="getResearchTeamLink(item, true)" target="_blank" v-for="(item,index) in manageList" class="research-team-item">
                            <div class="research-team-cover-ctn">
                                <!-- <img src="../../../../assets/not.jpg" class="research-team-cover"/> -->
                                <ResearchGroupIcon :name="item.name"></ResearchGroupIcon>
                            </div>
                            <div class="research-team-info">
                                <div class="team-name">
                                    {{ item.name }}
                                </div>
                                <div class="member-count">
                                    {{ item.userCount }} 人
                                </div>
                            </div>
                        </a>
                    </div>
                </a-spin>
            </a-collapse-panel>
        </a-collapse>
    </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue-demi';
import { getMyManageGroup, getMyJoinGroup } from '@/api/paperManage.js';
import ResearchGroupIcon from '../../components/researchGroupIcon.vue';

export default {
    props: {},
    setup(props, { root }) {
        const activeKey = ref(["join", "manage"]); // 展开的项
        const joinList = ref([]); // 我参与的课题组
        const manageList = ref([1, 2, 3]); // 我管理的课题组
        const isListLoading = ref(true); // 是否正在加载
        // 获取
        const getManageList = async () => {
            const res = await getMyManageGroup({
                isManage: true
            });
            if (res.success) {
                manageList.value = res.data;
            }
        };
        const getJoinList = async () => {
            const res = await getMyManageGroup({
                isManage: false
            });
            if (res.success) {
                joinList.value = res.data;
            }
        };
        onBeforeMount(async () => {
            isListLoading.value = true;
            /* setTimeout(() => {
                joinList.value = [1,2,3,4,5,7,8,1];
                manageList.value = [1,2,3];
                isListLoading.value = false;
            }, 3000) */
            await Promise.all([getManageList(), getJoinList()]);
            isListLoading.value = false;
        });
        const getResearchTeamLink = (item, hasManage) => {
            const { $route, $router } = root;
            const href = $router.resolve({
                name: "researchTeamPost",
                query: {
                    id: item.id,
                    mode: hasManage ? 'manage' :''
                }
            });
            return href.href;
        };
        return {
            activeKey,
            joinList,
            manageList,
            isListLoading,
            getResearchTeamLink
        };
    },
    components: { ResearchGroupIcon }
}
</script>

<style lang="less" scoped>
.research-team-card {
    border: .5px solid #EBEBEB;
    background: #fff;
    .research-team-item {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        transition: .3s all ease-in-out;
        margin-right: 4;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
            background: #f6f6f6;
        }
        .research-team-cover {
            width: 40px;
            height: 40px;
            object-fit: cover;
        }
        .research-team-info {
            width: 0px;
            flex: 1;
            margin-left: 10px;
            .team-name {
                color: rgba(0, 0, 0, 0.65);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .member-count {
                color: rgba(0, 0, 0, 0.45);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    /deep/ .ant-collapse-item {
        border: none;
        background: #fff;
    }
    /deep/ .ant-collapse-header {
        color: rgba(0, 0, 0, 0.85) !important;
    }
    /deep/ .ant-collapse-content-box {
        padding: 10px 4px;
    }
    /deep/ .ant-collapse-content {
        border-top: none;
    }
    /deep/ .ant-collapse {
        border: none;
    }
}
</style>