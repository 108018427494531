<!-- 推荐话题 侧边卡单个列表项-->
<template>
    <a class="recommand-research-team-item" :href="getHref" target="_blank">
       <!--  <img class="team-img" src="../../../../assets/not.jpg" /> -->
       <div>
         <ResearchGroupIcon :name="subjectData.subjectName"></ResearchGroupIcon>
       </div>
        <div class="info-ctn">
            <div class="title" :title="`# ${subjectData.subjectName} \n ${ subjectData.articles } 条内容 \t ${subjectData.followUsers}人关注`">
                # {{ subjectData.subjectName }}
            </div>
            <div class="sub-info-ctn">
                <div class="sub-info">
                    {{ subjectData.articles }} 条内容
                </div>
                <div class="sub-info">
                    {{ subjectData.followUsers }}人关注
                </div>
            </div>
        </div>
        <div class="operation-ctn">
            <a-button v-if="!subjectData.isFollow" type="plain" class="subscribe-btn operation-btn" size="small"
                @click.prevent="handleToggleFollow" @mouseover="(e) => handleRecentSubscribeMouseIn(e, index)"
                @mouseleave="(e) => handleRecentSubscribeMouseLeave(e, index)"
                :loading="isSubmitting">
                + 关注 </a-button>
            <a-button v-else-if="subjectData.isFollow && isRecentSub" type="plain" class="unsubscribe-btn operation-btn" size="small"
            @click.prevent="handleToggleFollow" @mouseover="(e) => handleRecentSubscribeMouseIn(e, index)"
                @mouseleave="(e) => handleRecentSubscribeMouseLeave(e, index)"
                :loading="isSubmitting"> {{ isHover ? '取消关注' : '已关注' }} </a-button>
            <a-button v-else-if="subjectData.isFollow" type="plain" class="unsubscribe-btn operation-btn" size="small"
            @click.prevent="handleToggleFollow" @mouseover="(e) => handleRecentSubscribeMouseIn(e, index)"
                @mouseleave="(e) => handleRecentSubscribeMouseLeave(e, index)"
                :loading="isSubmitting">
                取消关注 </a-button>
           
        </div>
    </a>
</template>

<script>
import { computed, ref } from 'vue-demi';
import { changeSubjectFollow } from '@/api/paperManage.js';
import { message } from 'ant-design-vue';
import ResearchGroupIcon from '../../components/researchGroupIcon.vue';
export default {
    props: {
        index: {
            default: -1
        },
        subjectData: {
            default: () => ({})
        }
    },
    setup(props, context) {
        const isHover = ref(false);
        // 鼠标移入操作按钮事件
        const handleRecentSubscribeMouseIn = (e, index) => {
            isHover.value = true;
        };
        const handleRecentSubscribeMouseLeave = (e, index) => {
            isHover.value = true;
        };
        // 是否是最近关注
        const isRecentSub = ref(false);
        // 是否正在提交关注
        const isSubmitting = ref(false);
        const { $router } = context.root;
        // 获取话题链接
        const getHref = computed(() => {
            const href = $router.resolve({
                name: "topicPost",
                query: {
                    id: props.subjectData.subjectId
                }
            });
            return href.href;
        });
        const handleToggleFollow = async () => {
            /* if(props.subjectData.isFollow) {

            } */
            isSubmitting.value = true;
            const newVal = !props.subjectData.isFollow;
            const formData = new FormData();
            formData.append('subjectId', props.subjectData.subjectId)
            formData.append('isFollow', newVal)
            const res = await changeSubjectFollow(formData);
            isRecentSub.value = false;
            if (res.success) {
                context.emit("onUpdate", {
                    ...props.subjectData,
                    isFollow: newVal
                });
                if (newVal) {
                    message.success("成功关注");
                    isRecentSub.value = true;
                }
                else {
                    message.info("取消关注");
                }
            }
            isSubmitting.value = false;
        };
        return {
            handleRecentSubscribeMouseIn,
            handleRecentSubscribeMouseLeave,
            isHover,
            isRecentSub,
            handleToggleFollow,
            isSubmitting,
            getHref
        };
    },
    components: { ResearchGroupIcon }
}
</script>

<style lang="less" scoped>
.recommand-research-team-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.65);
    padding: 8px 16px;
    background: #fff;
    transition: .3s all ease-in-out;

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }

    .team-img {
        width: 40px;
        height: 40px;
    }

    .info-ctn {
        flex: 1;
        width: 0;
        margin-left: 8px;

        .title {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
        }

        .sub-info-ctn {
            display: flex;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.45);
            margin-top: 3px;

            .sub-info {
                width: 50%;
            }

        }
    }

    .operation-ctn {
        .operation-btn {
            width: 64px;
            font-size: 12px;
        }

        .subscribe-btn {
            border: 0.5px solid transparent;
            background: #F0F5FF;
            color: @srims-primary-color;
            transition: .3s all ease-in-out;
            border-radius: 2px;

            &:hover {
                opacity: 0.8;
                border: 0.5px solid @srims-primary-color;
            }
        }

        .unsubscribe-btn {
            border: 0.5px solid transparent;
            background: #F6F6F6;
            color: rgba(0, 0, 0, 0.65);
            transition: .3s all ease-in-out;
            border-radius: 2px;

            &:hover {
                color: @srims-primary-color;
                opacity: 0.8;
                background: #fff;
                border: 0.5px solid @srims-primary-color;
            }
        }
    }
}
</style>