<template>
    <div class="side-gap-card-ctn">
        <div class="side-gap-card-title-ctn">
            <div class="title-gap" :style="{ background: gapColor }">

            </div>
            <div class="side-gap-card-title">
                {{ title  }}
            </div>
        </div>
        <div class="gap-card-content">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="less" scoped>
.side-gap-card-ctn {
    background: #fff;
    border: 0.5px solid #EBEBEB;
}
.side-gap-card-title-ctn {
    padding: 12px 16px;
    border-bottom: 1px solid #EBEBEB;
    display: flex;
    align-items: center;
}
.title-gap {
    width: 4px;
    height: 16px;
    background: @srims-primary-color;
}
.side-gap-card-title {
    margin-left: 8px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;
}

.gap-card-content {
    padding: 16px;
}

</style>

<script>

export default {
    props: {
        gapColor: {
            default: '#2F54EB'
        },
        title: {
            default: ''
        }
    },
    data() {
        return {

        }
    }
}
</script>