<template>
    <div>
        <a-avatar :size="size" :shape="shape" style="background: #00a870;">
            {{ name }}
        </a-avatar>
    </div>
</template>
<script>
export default {
    props: {
        name: {
            default: ''
        },
        size: {
            default: 40,
        },
        width: {
            default: 40
        },
        height: {
            default: 40
        },
        shape: {
            default: 'square'
        }
    }

}
</script>