<template>
    <div>
        <a-avatar v-if="url" :size="size" :src="url">
        </a-avatar>
        <a-avatar v-else-if="name" :size="size" style="background: #00a870;">
            {{ name.toString()[name.toString().length - 1] }}
        </a-avatar>
    </div>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            default : ''
        },
        name: {
            type: String,
            default: ''
        },
        size: {
            default: 40
        }
    },
    setup(props,context) {
        return {

        }
    }
}
</script>