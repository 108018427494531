<template>
    <div class="user-header-ctn">
        <div class="top-bar">
            <div class="top-bar-content">

            </div>
        </div>
        <a-skeleton :loading="!userInfo" :avatar="{ size: 100 }" :paragraph="{ rows: 4 }" style="position: relative; z-index: 100; width: 100%;">
            <div v-if="userInfo" class="user-info-ctn">
                    <div class="user-avatar">
                        <Avatar :name="userInfo.name" :url="userInfo.avatar" :size="100"></Avatar>
                    </div>
                    <div class="user-info">
                        <div class="info-header">
							<a-spin :spinning="isNewNickNameSubmitting">
								<div class="user-name" >
									<div v-if="!isEdittingNickName ">
										{{ userInfo.name }}
									</div>
									<div v-if="isEdittingNickName && isSelf" style="width: 200px;">
										<a-input size="large" ref="newNickNameInput" v-model="newNickName" placeholder="昵称不超过20个字符" :maxLength="20"></a-input>
									</div>
								
									<a-tooltip v-if="!isEdittingNickName && isSelf" placement="top" title="修改昵称" >
										<svg-icon name="intro-edit" class="intro-edit" @click="handleEditNickNameTap" style="font-size: 18px; margin-left: 20px;cursor: pointer;"></svg-icon>
									</a-tooltip>
									<div v-if="isEdittingNickName && isSelf">
										<a-button type="link" size="small" @click="handleNewNickNameSubmit"> 保存 </a-button>
									</div>
									<div v-if="isEdittingNickName && isSelf">
										<a-button  style="color: #999;" type="link" size="small" @click="isEdittingNickName = false"> 取消 </a-button>
									</div>
								</div>
							</a-spin>
                           <!--  <div class="user-pos">
                                学生
                            </div> -->
                        </div>
                        <div style="display: flex;">
                            
                        </div>
                        <div class="intro-ctn">
                            <div class="intro">
                                <span>
                                    个人简介：
                                </span>
                                <span v-if="!isEditIntro" class="intro-text">
                                    {{ !userInfo.biography ? '-' : userInfo.biography }}
                                </span>
                                <div style="width: 0; flex: 1; max-width: 500px;" v-if="isEditIntro">
                                    <a-spin :spinning="isSubmittingUserIntro">
                                        <div>
                                            <a-input type="textarea"  :auto-size="{ minRows: 3, maxRows: 5 }" :maxLength="200" v-model="newIntro" placeholder="请输入"> </a-input>
                                        </div>
                                        <div style="text-align: right;">
                                            <a-button type="link" @click="handleEditUserIntroSubmit"> 保存 </a-button>
                                            <a-button type="link" style="color: #999;" @click="isEditIntro = false;"> 取消 </a-button>
                                        </div>
                                    </a-spin>
                                </div>
                                <span class="edit-intro" >
                                    <a-tooltip v-if="!isEditIntro && isSelf" placement="top" title="编辑" >
                                        <svg-icon name="intro-edit" class="intro-edit" @click="handleEditTap"></svg-icon>
                                    </a-tooltip>
                                </span>
                            </div>
                            
                            <div class="operation"  v-if="!isSelf ">
                                <a-button v-if="userInfo.hasFollow == 1" type="primary" class="subscribe-btn"
                                    @click="follow" :disabled="isFollowing">
                                    + 关注
                                </a-button>
                                <a-button v-else type="plain" class="unsubscribe-btn" @click="unfollow" :disabled="isFollowing">
                                    {{ userInfo.hasFollow == 2 ? '已关注' : '互相关注'  }}
                                </a-button>
                            </div>
                        </div>

                    </div>
            </div>
        </a-skeleton>
    </div>
</template>

<script>
import { computed, nextTick, ref } from 'vue-demi';
import Avatar from '../../components/avatar.vue';
import { editUserBiography,followUser, unFollowUser } from '@/api/paperManage.js';
import { message } from 'ant-design-vue';
import { updateNickName } from '@/api/others.js';
export default {
    components: {
        Avatar
    },
    props: {
        userInfo: {
            default: null
        },
    },
    setup(props, context) {
        const { $route } = context.root;
        const isSelf = computed(() => {
            return $route.params.id == '0';
        })

        const isEditIntro = ref(false); // 是否正在编辑个人简介

       // const 

       const handleEditTap = () => {
            newIntro.value = props.userInfo.biography;
            isEditIntro.value = true;
       }

       const newIntro = ref('')

       const isSubmittingUserIntro = ref(false);    // 是否正在提交个人简介

       // 点击修改个人简介
       const handleEditUserIntroSubmit = async () => {
            isSubmittingUserIntro.value = true;
            const res = await editUserBiography({
                biography: newIntro.value
            });
            if(res.success) {
                message.success('修改成功');
                context.emit('onUpdateBiography', newIntro.value);
                isEditIntro.value = false;
            }
            isSubmittingUserIntro.value = false;
       }

       const isFollowing = ref(false);  // 是否正在关注中

       const follow = async () => {
        isFollowing.value = true;
        const res = await followUser({
            userid: props.userInfo.id
        });
        if(res.success) {
            message.success('成功关注')
            context.emit('onUpdateFollow', 2)
        }
        isFollowing.value = false;
       }
       const unfollow = async () => {
        isFollowing.value = true;
        const formData = new FormData();
        formData.append('userid', props.userInfo.id)
        const res = await unFollowUser(formData);
        if(res.success) {
            message.info('已取消关注')
            context.emit('onUpdateFollow',1)
        }
        isFollowing.value = false;
       }

       const isEdittingNickName = ref(false);

       const newNickName = ref('');

       const newNickNameInput = ref();

       // 点击修改昵称
       const handleEditNickNameTap = () => {
        newNickName.value = props.userInfo.name;
        isEdittingNickName.value = true;
        nextTick(() => {
            newNickNameInput.value && newNickNameInput.value.focus();
        })
       }

       // 是否正在提交新昵称修改
       const isNewNickNameSubmitting = ref(false);

       const handleNewNickNameSubmit = async () => {
		isNewNickNameSubmitting.value = true;
		const res = await updateNickName({
			nickName: newNickName.value
		});
		if(res.success) {
			message.success('成功修改昵称');
			context.emit('onUpdateNickName',  newNickName.value)
			isEdittingNickName.value = false;
		}
		
		isNewNickNameSubmitting.value = false;
       }

        return {
            isSelf,
            isEditIntro,
            handleEditTap,
            isSubmittingUserIntro,
            handleEditUserIntroSubmit,
            newIntro,
            isFollowing,
            follow,
            unfollow,
            handleEditNickNameTap,
            isEdittingNickName,
            newNickName,
            newNickNameInput,
            isNewNickNameSubmitting,
            handleNewNickNameSubmit
        }
    }
}
</script>

<style lang="less" scoped>
.user-header-ctn {
    padding: 60px 40px 40px 40px;
    position: relative;
    margin-top: 20px;
    border: .5px solid #EBEBEB;
    background: #FFF;

    .top-bar {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 80px;
        z-index: 1;

        .top-bar-content {
            height: 100%;
            width: 100%;
            background: @srims-primary-color;
            position: relative;
            overflow: hidden;
            z-index: 0;

            &::before {
                content: '';
                z-index: 200;
                position: absolute;
                width: 217px;
                height: 174px;
                border-radius: 86.8px;
                background: linear-gradient(93deg, #4265F1 2%, rgba(66, 101, 241, 0.00) 91%);
                filter: blur(1px);
                box-shadow: -6px 10px 26px 0px rgba(15, 47, 176, 0.15);
                right: -10px;
                top: -48.8px;
            }

            &::after {
                content: '';
                z-index: 100;
                position: absolute;
                width: 512px;
                height: 228px;
                border-top-left-radius: 228px;
                border-top-right-radius: 228px;
                background: linear-gradient(90deg, rgba(66, 101, 241, 0.55) 6%, rgba(66, 101, 241, 0.00) 53%);
                filter: blur(1px);
                top: -42px;
                right: -162px;

            }
        }
    }

    .user-info-ctn {
        display: flex;
        z-index: 100;
        position: relative;
        width: 100%;

        .user-avatar {
            /deep/ .ant-avatar-circle {
                font-size: 26px !important;
                border: 2px solid #FFFFFF;
            }
        }

        /deep/ .user-info {
            margin-left: 20px;
            padding-top: 38px;
            width: 0px;
            flex: 1;

            .info-header {
                display: flex;
                align-items: center;

                .user-name {
                    font-size: 24px;
                    color: rgba(0, 0, 0, 0.85);
                    display: flex;
                    align-items: center;
                    .intro-edit {
                        // font-size: 32px;
                        transform: translateY(2px);

                        &:active,
                        &:focus {
                            outline: none;
                        }
                    }
                }

                .user-pos {
                    font-size: 14px;
                    color: #8590A6;
                    padding: 0px 8px;
                    margin-left: 16px;
                    position: relative;
                    z-index: 100;
                    margin-top: 4px;


                    &:before {
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 10px;
                        top: 3px;
                        left: 2px;
                        z-index: 10;
                        border-left: 1px solid #8590A6;
                        border-top: 1px solid #8590A6;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 10px;
                        bottom: 3px;
                        right: 1px;
                        z-index: 10;
                        border-right: 1px solid #8590A6;
                        border-bottom: 1px solid #8590A6;
                    }
                }
            }

            .intro-ctn {
                margin-top: 16px;
                display: flex;
                word-break: break-all;

                //justify-content: space-around;
                .intro {
                    padding-right: 50px;
                    width: 0px;
                    flex: 1;
                    font-size: 12px;
                    color: #4E5969;
                    fill: #4E5969;
                    word-break: break-all;
                    display: flex;

                }

                .operation {

                    .subscribe-btn,
                    .unsubscribe-btn {
                        width: 88px;
                    }

                    .unsubscribe-btn {
                        border: 0.5px solid transparent;
                        background: #F4F4F4;
                        color: rgba(0, 0, 0, 0.65);
                        transition: .3s all ease-in-out;
                        border-radius: 4px;

                        &:hover {
                            color: @srims-primary-color;
                            opacity: 0.8;
                            background: #fff;
                            border: 0.5px solid @srims-primary-color;
                        }
                    }
                }

                .intro-text {
                    word-break: break-all;
                }

                .edit-intro {
                    margin-left: 12px;
                    font-size: 14px;
                    cursor: pointer;
                    transition: .3s all ease-in-out;

                    &:hover {
                        fill: @srims-primary-color;
                    }

                    &:active,
                    &:focus {
                        outline: none;
                    }

                    .intro-edit {
                        // font-size: 32px;
                        transform: translateY(2px);

                        &:active,
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }

    }
}
</style>