<template>
    <div class="user-base-info-card">
        <div class="info-row">
            <div class="info-col">
                <div class="info-col-title">
                    帖子
                </div>
                <div class="info-col-content" :title="userCountInfo && userCountInfo.paperCount">
                    <span v-if="!userCountInfo">
                        <a-icon type="loading" />
                    </span>
                    <span v-else>
                       {{ userCountInfo.paperCount }}
                    </span>
                </div>
            </div>
            <div class="inf-gap"></div>
            <div class="info-col">
                <div class="info-col-title">
                    关注
                </div>
                <div class="info-col-content" :title="userCountInfo && userCountInfo.myFollowCount">
                    <span v-if="!userCountInfo">
                        <a-icon type="loading" />
                    </span>
                    <span v-else>
                        {{ userCountInfo.myFollowCount }}
                    </span>
                </div>
            </div>
            <div class="inf-gap"></div>
            <div class="info-col">
                <div class="info-col-title">
                    粉丝
                </div>
                <div class="info-col-content" :title="userCountInfo && userCountInfo.followMeCount">
                    <span v-if="!userCountInfo">
                        <a-icon type="loading" />
                    </span>
                    <span v-else>
                        {{ userCountInfo.followMeCount }}
                    </span>
                </div>
            </div>
        </div>
        <div class="info-row">
            <div class="info-col">
                <div class="info-col-title">
                    获赞
                </div>
                <div class="info-col-content"  :title="userCountInfo && userCountInfo.likeCount">
                    <span v-if="!userCountInfo">
                        <a-icon type="loading" />
                    </span>
                    <span v-else>
                        {{ userCountInfo.likeCount }}
                    </span>
                </div>
            </div>
            <div class="inf-gap"></div>
            <div class="info-col">
                <div class="info-col-title">
                    被收藏
                </div>
                <div class="info-col-content" :title="userCountInfo && userCountInfo.collectCount">
                    <span v-if="!userCountInfo">
                        <a-icon type="loading" />
                    </span>
                    <span v-else >
                        {{ userCountInfo.collectCount }}
                    </span>
                </div>
            </div>
            <div class="inf-gap"></div>
            <div class="info-col">
                <div class="info-col-title">
                    课题组
                </div>
                <div class="info-col-content" :title="userCountInfo && userCountInfo.groupCount">
                    <span v-if="!userCountInfo">
                        <a-icon type="loading" />
                    </span>
                    <span v-else>
                        {{ userCountInfo.groupCount }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        userCountInfo: {
            default: null
        }
    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.user-base-info-card {
    border: .5px solid #EBEBEB;
    background: #fff;
}
.info-row {
    display: flex;
    align-items: center;
    &:nth-child(2) {
        border-top: .5px solid #EBEBEB;
    }
    .inf-gap {
        width: 1px;
        background: #EBEBEB;
        height: 40px;
    }
    .info-col {
        padding: 20px;
        flex: 1;
        width: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
        .info-col-title {
            color: rgba(0, 0, 0, 0.45);

        }
        .info-col-content {
            margin-top: 4px;
            color: #4E5969;
            font-size: 20px;
            line-height: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
        }
    }
}
</style>