import { render, staticRenderFns } from "./userHeader.vue?vue&type=template&id=15c42357&scoped=true&"
import script from "./userHeader.vue?vue&type=script&lang=js&"
export * from "./userHeader.vue?vue&type=script&lang=js&"
import style0 from "./userHeader.vue?vue&type=style&index=0&id=15c42357&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15c42357",
  null
  
)

export default component.exports