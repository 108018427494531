var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-layout"},[_c('div',{staticClass:"user-layout-header"},[_c('UserHeader',{attrs:{"userInfo":_vm.userInfo,"isSelf":_vm.isSelf},on:{"onUpdateBiography":_vm.onUpdateBiography,"onUpdateFollow":function (val) { return _vm.userInfo.hasFollow = val; },"onUpdateNickName":function (val) { return _vm.userInfo.name = val; }}})],1),_c('div',{staticClass:"user-layout-content"},[_c('div',{staticClass:"left-content"},[_c('a-tabs',{on:{"change":_vm.handleTabChange},model:{value:(_vm.activeTabkey),callback:function ($$v) {_vm.activeTabkey=$$v},expression:"activeTabkey"}},_vm._l((_vm.tabList),function(tabItem,index){return _c('a-tab-pane',{key:tabItem.key,attrs:{"tab":tabItem.tab}},[(tabItem.key === 'post-list')?_c('div',[_c('div',{staticClass:"post-list-header"},[_c('div',{staticClass:"header-item"},[_c('router-link',{attrs:{"to":{
                                    name: 'bbsUserPosts', query: Object.assign({}, _vm.$route.query)
                                },"exact":"","active-class":"active-router-link"}},[_vm._v(" "+_vm._s(_vm.isSelf ? '我' : 'ta')+"的帖子 ")]),_c('span',{staticClass:"header-item-count"},[_vm._v(" "+_vm._s(_vm.postCount)+" ")])],1),_c('div',{staticClass:"header-item"},[_c('router-link',{attrs:{"to":{
                                    name: 'bbsUserReplyList', query: Object.assign({}, _vm.$route.query)
                                },"exact":"","active-class":"active-router-link"}},[_vm._v(" "+_vm._s(_vm.isSelf ? '我' : 'ta')+"的回帖 ")]),_c('span',{staticClass:"header-item-count"},[_vm._v(" "+_vm._s(_vm.commentCount)+" ")])],1)]),_c('div',[(_vm.activeTabkey === 'post-list' && _vm.userInfo)?_c('router-view',{attrs:{"userInfo":_vm.userInfo},on:{"onChangePostCount":_vm.onChangePostCount}}):_vm._e()],1)]):(tabItem.key === 'subscribe')?_c('div',[_c('div',{staticClass:"subscribe-list-header"},[_c('div',{staticClass:"header-item"},[_c('router-link',{attrs:{"to":{
                                    name: 'bbsUserFollow', query: Object.assign({}, _vm.$route.query)
                                },"exact":"","active-class":"active-router-link"}},[_vm._v(" "+_vm._s(_vm.isSelf ? '我' : 'ta')+"的关注 ")]),_c('span',{staticClass:"header-item-count"},[_vm._v(" "+_vm._s(_vm.followCount)+" ")])],1),_c('div',{staticClass:"header-item"},[_c('router-link',{attrs:{"to":{
                                    name: 'bbsUserFollowerList', query: Object.assign({}, _vm.$route.query)
                                },"exact":"","active-class":"active-router-link"}},[_vm._v(" 关注"+_vm._s(_vm.isSelf ? '我' : 'ta')+"的 ")]),_c('span',{staticClass:"header-item-count"},[_vm._v(" "+_vm._s(_vm.followMeCount)+" ")])],1)]),(_vm.activeTabkey === 'subscribe' && _vm.userInfo)?_c('div',[_c('router-view',{attrs:{"userInfo":_vm.userInfo},on:{"onChangeFollowCount":_vm.onChangeFollowCount}})],1):_vm._e()]):(tabItem.key === 'collection' && _vm.userInfo)?_c('div',[_c('div',{staticClass:"collection-list-header"},[_c('div',{staticClass:"header-item"},[_c('router-link',{attrs:{"to":{
                                    name: 'bbsUserPostCollection', query: Object.assign({}, _vm.$route.query)
                                },"exact":"","active-class":"active-router-link"}},[_vm._v(" 我的收藏 ")]),_c('span',{staticClass:"header-item-count"},[_vm._v(" "+_vm._s(_vm.collectCount)+" ")])],1)]),(_vm.activeTabkey === 'collection'  && _vm.userInfo)?_c('div',[_c('router-view',{attrs:{"userInfo":_vm.userInfo},on:{"onCollectCountChange":_vm.onCollectCountChange}})],1):_vm._e()]):(tabItem.key === 'drafts')?_c('div',[_c('div',{staticClass:"draft-list-header"},[_c('div',{staticClass:"header-item"},[_c('router-link',{attrs:{"to":{ name: 'bbsUserDrafts', query: Object.assign({}, _vm.$route.query) },"exact":"","active-class":"active-router-link"}},[_vm._v(" 我的草稿箱 ")]),_c('span',{staticClass:"header-item-count"},[_vm._v(" "+_vm._s(_vm.draftCount)+" ")])],1)]),(_vm.activeTabkey === 'drafts')?_c('div',[_c('router-view',{on:{"onChangeDraftCount":_vm.onChangeDraftCount}})],1):_vm._e()]):_vm._e()])}),1)],1),_c('div',{staticClass:"right-content"},[_c('UserBaseInfoCard',{attrs:{"userCountInfo":_vm.infoCount},on:{"update:userCountInfo":function($event){_vm.infoCount=$event},"update:user-count-info":function($event){_vm.infoCount=$event}}}),(_vm.isSelf)?_c('ResearchTeamCard',{staticStyle:{"margin-top":"20px"}}):_vm._e(),(_vm.isSelf)?_c('div',{staticClass:"recommand-research-team-ctn"},[_c('SideGapCard',{attrs:{"title":"我关注的话题","gapColor":"#2F54EB"}},[[_c('a-skeleton',{style:({ padding: _vm.isRecomandTopicLoading ? '20px' : '' }),attrs:{"loading":_vm.isRecomandTopicLoading,"active":"","title":false,"paragraph":{ rows: 10, width: '100%' }}},[_c('div',{staticClass:"recomand-list"},_vm._l((_vm.recommandTopicList),function(item,index){return _c('div',[_c('TopicListItem',{attrs:{"index":index,"subjectData":item},on:{"onUpdate":function (newVal) { return item.isFollow = newVal.isFollow; }}})],1)}),0),(_vm.recommandTopicList.length === 0)?_c('a-empty',{staticStyle:{"margin":"20px"}}):_vm._e()],1)]],2)],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }