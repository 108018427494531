// 论坛模块 接口
import  request from "../utils/request";
// 新增帖子
export const addPaper = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/addpaper',
        data
    })
}

// 更新帖子
export const updatePaper = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/updatepaper',
        data
    })
}

// 查询单个帖子信息
export const getPaperInfo = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getpaperinfo',
        params
    })
}

// 选择话题
export const searchSubjectRecord = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/searchSubjectRecord',
        params
    })
}

// 发布帖子
export const publishPaper = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/publishedpaper',
        data
    })
}

// 发帖时获取全部的话题列表
export const getAllSubjectDropDownList = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getSubjectDropdownlist',
        params
    })
}

// 发帖时获取所有的课题组列表
export const getAllResearchGroupDropDown = () => {
    return request({
        method: 'get',
        url: '/papermanage/getResearchGroupDropdownlist',
    })
}

// 获取推荐话题列表前5条
export const getHotSubject = () => {
    return request({
        method: 'get',
        url: '/papermanage/getHotSubject',
    })
}

// 获取个人主页数量集合
export const getUserCenterCount = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getother',
        params
    })
}

// 获取个人主页用户信息
export const getUserCenterUserInfo = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getuserinfo',
        params
    })
}

// 修改个人简介
export const editUserBiography = (data) => {
    return request({
        method: 'post',
        url: '/user/inputBiography',
        data
    })
}

// 获取草稿列表
export const getDraftPaperList = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getdraftpaper',
        params
    })
}

// 获取收藏的帖子
export const getMyCollectionList = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getmycollectpaper',
        params
    })
}

// 查询已发布的帖子
export const getUserPublishPaper = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getpublishedpaper',
        params
    })
}

// 查询我评论的帖子 即 我的回帖
export const getUserCommentPaper = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getcommentpaper',
        params
    })
}

// 获取个人中心我关注最新的5条话题
export const getMyFollowSubjectList = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/myFollowSubjectList',
        params
    })
}


// 关注/取消关注话题
export const changeSubjectFollow = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/followSubject',
        data
    })
}

// 查询个人中心我管理的课题组 / 我参与的课题组
export const getMyManageGroup = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getMyGroup',
        params
    })
}

// 查询个人中心我参与的课题组
/* export const getMyJoinGroup = () => {
    return request({
        method: 'get',
        url: '/papermanage/getmyjoingroup'
    })
} */

// 删除帖子
export const deletePaper = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/deletepaper',
        data
    })
}

// 关注用户
export const followUser = (params) => {
    return request({
        method: 'post',
        url: '/papermanage/followuser',
        params
    })
}

// 取消关注
export const unFollowUser = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/unfollow',
        data
    })
}

// 获取我的回帖列表
export const getMyCommentPaper = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getcommentpaper',
        params
    })
}

// 获取话题详情
export const getSubjectDetail = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getSubjectDetail',
        params
    })
}

// 获取话题帖子
export const getSubjectArticles = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/subjectArticlesPage',
        params
    })
}

/* // 获取文章的内容
export const get */

// 获取课题组帖子信息
export const getGroupPostInfo = (params) => {
    return request({
        method: 'get',
        url: '/researchgrouppapermanage/getgrouppapersum',
        params
    })
}

// 点赞帖子
export const likePost = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/likepaper',
        data
    })
}

// 取消喜欢帖子
export const unLikePost = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/cancellike',
        data
    })
}

// 收藏
export const collectPost = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/collectpaper',
        data
    })
}

// 取消收藏
export const unCollectPost = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/unbookmark',
        data
    })
}

// 近期热榜
export const getRecentHotList = () => {
    return request({
        method: 'get',
        url: '/papermanage/getrecenthotlist'
    })
}

// 查看帖子评论
export const getCommentInfo = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getcommentinfos',
        params
    })
}

// 查看单个评论
export const getSingleComment = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getsinglecommentinfos',
        params
    })
}

// 评论或回复
export const replyComment = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/replycomment',
        data
    })
}

// 获取我关注的用户
export const getMyFollowUser = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getmyfollow',
        params
    })
}

// 获取关注我的用户
export const getFollowMeUser = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getfollowme',
        params
    })
}

// 上传文件
export const uploadFileToPaper = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/uploadfile',
        data,
        timeout: 60 * 60 * 1000
    })
}

// 删除封面
export const deletePaperCover = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/deletepapercoverphoto',
        data
    })
}


// 修改帖子的可见范围
export const setPostVisibleRange = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/updatepapervisiblerange',
        data
    })
}

// 修改帖子是否允许评论
export const setPostIsAllowedComment = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/updatepaperallowedcomment',
        data
    })
}

// 获取首页数据
export const getIndexPaperList = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/homequery',
        params
    })
}

// 删除评论
export const deleteComment = (data) => {
    return request({
        method: 'post',
        url: '/papermanage/deletecomment',
        data
    })
}

// 获取用户消息通知数量
export const getBBSNoticeCount = () => {
    return request({
        method: 'get',
        url: '/papermanage/getUserNotice'
    })
}

// 获取最近我的帖子下的评论消息
export const getCommentPageList = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/commentPaperPage',
        params
    })
}

// 获取最近关注我的粉丝消息
export const getRecentFollowList = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/followMeUserPage',
        params
    })
}

// 获取最近点赞或收藏我的帖子消息
export const getRecentFollowOrCollectList = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/likeAndCollectionMyPaperPage',
        params
    })
}

// 获取相关推荐帖子
export const getRecommendedPaper = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/getrecommendedpaper',
        params
    })
}

// 获取上传图片路径
export const getPaperFileUrl = (params) => {
    return request({
        method: 'post',
        url: '/papermanage/getPaperFileUrl',
        params
    })
}

// 查询帖子
export const getQueryPaper = (params) => {
    return request({
        method: 'get',
        url: '/papermanage/queryPaper',
        params
    })
}